<!-- 我的申请-查看 -->
<template>
  <div class='my'>
    <back />
    <div class="table">
      <div class="title">调价信息</div>
      <div class="form">
        <el-form :model="ruleForm" ref="ruleForm" label-width="120px">
          <el-row class="dialog_row ">
            <el-col :span="8">
              <!-- <el-form-item class="item" label="商品编号" prop="serialId">
                <el-input v-model="ruleForm.serialId" readonly></el-input>
              </el-form-item> -->
              <el-form-item class="item" label="商品条形码" prop="barCode">
                <el-input v-model="ruleForm.barCode" readonly></el-input>
              </el-form-item>
              <el-form-item class="item" label="规格" prop="specs">
                <el-input v-model="ruleForm.specs" readonly></el-input>
              </el-form-item>
              <el-form-item class="item" label="审核状态" prop="statusDesc">
                <el-input v-model="ruleForm.statusDesc" readonly
                  :class="{ 'red': ruleForm.statusDesc === '待审核' }"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item class="item" label="商品名称" prop="productName">
                <el-input v-model="ruleForm.productName" readonly></el-input>
              </el-form-item>
              <el-form-item class="item" label="招标价格" prop="oldPrice">
                <el-input v-model="ruleForm.oldPrice" readonly></el-input>
              </el-form-item>
              <el-form-item class="item" label="申请时间" prop="createdTime">
                <el-input v-model="ruleForm.createdTime" readonly></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item class="item" label="商品分类" prop="categoryName">
                <el-input v-model="ruleForm.categoryName" readonly></el-input>
              </el-form-item>
              <el-form-item class="item" label="新价格" prop="newPrice">
                <el-input v-model="ruleForm.newPrice" readonly></el-input>
              </el-form-item>
              <el-form-item class="item" label="审核时间" prop="auditTime"
                v-if="ruleForm.statusDesc && ruleForm.statusDesc != '待审核'">
                <el-input v-model="ruleForm.auditTime" readonly></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="dialog_row ">
            <el-col :span="24">
              <el-form-item class="item" label="调价原因" prop="adjustReason">
                <el-input v-model="ruleForm.adjustReason" readonly></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="dialog_row unpass " v-if="ruleForm.statusDesc == '审核不通过'">
            <el-col :span="24">
              <el-form-item class="item" label="不通过原因" prop="refuseReason">
                <el-input v-model="ruleForm.refuseReason" readonly></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>

      <div class="submit" v-if="ruleForm.statusDesc == '审核不通过'">
        <el-button type="primary" @click="handleReplied">重新申请</el-button>
      </div>
    </div>

    <!-- 申请调价弹窗 -->
    <el-dialog title="重新申请调价" :visible.sync="dialogVisible" @close="handleCloseDialog">
      <el-form :model="reply" :rules="rulesReply" ref="reply" label-width="150px" class="demo-ruleForm">
        <el-row>
          <el-col :span="12">
            <el-form-item label="商品名称" prop="productName">
              <el-input v-model="reply.productName" placeholder="自动带出" readonly></el-input>
            </el-form-item>
            <el-form-item label="商品分类" prop="category">
              <el-input v-model="reply.category" placeholder="自动带出" readonly></el-input>
            </el-form-item>
            <el-form-item label="招标价格" prop="tenderProductPrice">
              <el-input v-model="reply.tenderProductPrice" placeholder="自动带出" readonly></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <!-- <el-form-item label="商品编号" prop="productCode">
              <el-input v-model="reply.productCode" placeholder="自动带出" readonly></el-input>
            </el-form-item> -->
            <el-form-item label="商品条形码" prop="barCode">
              <el-input v-model="reply.barCode" placeholder="自动带出" readonly></el-input>
            </el-form-item>

            <el-form-item label="规格" prop="scale">
              <el-input v-model="reply.scale" placeholder="自动带出" readonly></el-input>
            </el-form-item>
            <el-form-item label="新价格" prop="newPrice">
              <el-input v-model="reply.newPrice" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="22">
            <el-form-item label="调价原因" prop="reason">
              <el-input v-model="reply.reason" placeholder="请输入" type="textarea" maxlength="20"
                show-word-limit></el-input>
            </el-form-item>
          </el-col>
        </el-row>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleCloseDialog">取 消</el-button>
        <el-button type="primary" @click="submitForm('reply')">申请调价</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ruleForm: {},
      id: '',

      // 重新申请弹窗数据
      dialogVisible: false, // 弹窗显隐
      reply: {
        productName: '', // 商品名称
        barCode: '', // 商品条形码
        category: '', // 商品分类
        scale: '', // 规格
        tenderProductPrice: '', // 招标价格
        newPrice: '', // 新价格
        reason: '', // 调价原因
        categoryId: '', // 分类id
        categoryName: '', // 分类Name
      },
      rulesReply: {
        newPrice: [{
          required: true,
          message: '请输入新价格',
          trigger: 'blur'
        }],
        reason: [{
          required: true,
          message: '请输入调价原因',
          trigger: 'blur'
        }],
      },
    }
  },
  created() {
    this.id = this.$route.query.id;
    this.queryMySubscribeDetail();
  },
  methods: {
    // 查看详情
    queryMySubscribeDetail() {
      this.$axios.get(this.$api.queryMySubscribeDetail, {
        params: {
          id: this.id
        }
      }).then((res) => {
        if (res.data.code == 100) {
          this.ruleForm = res.data.result.result;
          // 判断状态并设置对应的状态描述
          switch (this.ruleForm.status) {
            case 0:
              this.ruleForm.statusDesc = '待审核';
              break;
            case 1:
              this.ruleForm.statusDesc = '审核通过';
              break;
            case 2:
              this.ruleForm.statusDesc = '审核不通过';
              break;
            default:
              this.ruleForm.statusDesc = '未知状态';
          }
        }
      })
    },
    // 重新申请
    handleReplied() {
      this.dialogVisible = true;
      const {
        productName,
        categoryName,
        categoryId,
        barCode,
        specs,
        oldPrice
      } = this.ruleForm
      this.reply.productName = productName;
      this.reply.category = categoryName;
      this.reply.categoryId = categoryId;
      this.reply.tenderProductPrice = oldPrice;
      this.reply.barCode = barCode;
      this.reply.scale = specs;
    },
    // 关闭弹窗
    handleCloseDialog() {
      this.dialogVisible = false;
      this.resetForm();
    },
    // 表单校验
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          console.log('error submit!!');
          return false;
        } else {
          this.$axios.post(this.$api.addProductAdjustPrice, {
            adjustReason: this.reply.reason,
            categoryId: this.reply.categoryId,
            newPrice: this.reply.newPrice,
            oldPrice: this.reply.tenderProductPrice,
            productName: this.reply.productName,
            barCode: this.reply.barCode,
            specs: this.reply.scale,
          }).then((res) => {
            if (res.data.code == 100) {
              this.$message.success('申请成功!');
              this.handleCloseDialog();
              this.$router.go(-1)
            }
          })
        }
        this.handleCloseDialog();
      });
    },
    // 重置表单
    resetForm() {
      this.reply = {};
      this.$refs.reply.resetFields();
    },
  },
}
</script>

<style lang="scss" scoped>
.dialog_row {
  /deep/ .el-form-item {
    margin-right: 60px;
  }

  /deep/.el-form-item__label {
    color: #272727;
    font-size: 16px;
    padding-right: 30px;
  }

  /deep/ .el-input__inner {
    background: #fff;
    border-radius: 10px;
    border: 1px solid rgba(3, 16, 14, 0.10);
  }
}

.my {
  .table {
    overflow: hidden;

    .title {
      height: 45px;
      line-height: 45px;
      padding-left: 30px;
      color: #1A1A1A;
      font-weight: bold;
    }

    .form {
      padding: 30px 0 30px 30px;
    }

    .item {
      padding-right: 50px;
    }

    .red {
      /deep/.el-input__inner {
        color: #EB2500;
      }
    }

    .unpass {

      /deep/.el-form-item__label,
      /deep/.el-input__inner {
        color: #EB2500;
      }
    }
  }
}

.submit {
  text-align: center;

  margin-top: 40px;

  .el-button {
    width: 100px;
  }
}

// 审核不通过原因弹窗
/deep/ .el-textarea__inner {
  resize: none;
  background-color: #f2f2f2;
  border-radius: 10px;
  height: 130px;
}

/deep/ .el-input__count {
  background-color: #f2f2f2;
}
</style>
